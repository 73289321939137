export const RECEIVE_CURRENT_LOCATION = 'iHR/Location/RECEIVE_CURRENT_LOCATION';
export const RECEIVE_CURRENT_MARKET = 'iHR/Location/RECEIVE_CURRENT_MARKET';
export const REJECT_CURRENT_MARKET = 'iHR/Location/REJECT_CURRENT_MARKET';
export const RECEIVE_DEFAULT_MARKET = 'iHR/Location/RECEIVE_DEFAULT_MARKET';
export const REJECT_DEFAULT_MARKET = 'iHR/Location/REJECT_DEFAULT_MARKET';

export const GEO_LAT = 'geoLat';
export const GEO_LONG = 'geoLong';
export const GEO_ZIP = 'geoZipcode';
export const GEO_COUNTRY = 'geoCountry';
