export const EXIT_TYPE = {
  USER_DISMISS: 'user_dismiss',
  AUTO_DISMISS: 'auto_dismiss',
  LINK1_CLICK: 'link1_click',
  LINK2_CLICK: 'link2_click',
  BACKGROUND: 'background',
  NAVIGATION: 'navigation',
} as const;

export const MESSAGE_TYPE = {
  HTML: 'HTML',
  FULLSCREEN: 'Fullscreen',
  SLIDEUP: 'Slideup',
  MODAL: 'Modal',
  BANNER: 'Banner',
} as const;
