import logger, { CONTEXTS } from 'modules/Logger';
import reduxFactory from 'state/factory';
import { getBlockedPIIBehaviors } from 'state/Profile/selectors';
import { getPageParameters, getScriptParameters } from './helpers';
import { loadScript } from '@iheartradio/web.utilities';
import type { EventTypeMap } from '../types';

export const epsilonLoginRegistration = async (
  data: EventTypeMap['post_login'] | EventTypeMap['post_registration'],
) => {
  const store = reduxFactory();

  const skipEvent = getBlockedPIIBehaviors(store.getState()).sanitizeStreams;
  const { profileId, email } = data;

  const pageParameters = getPageParameters({
    pageName: '',
  } as EventTypeMap['page_view']);
  const scriptParameters = await getScriptParameters(
    pageParameters,
    Number(profileId),
    'registrationLogin',
    email,
  );

  const url = `${window.location.protocol}//${window.location.host}/tag_path/profile/visit/js/1_0`;

  const scriptUrl = new URL(url);

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, val] of Object.entries(scriptParameters)) {
    scriptUrl.searchParams.append(key, String(val));
  }

  logger.info([CONTEXTS.ANALYTICS, 'Epsilon LoginRegistration'], {
    scriptParameters,
    skipEvent,
    url: scriptUrl.toString(),
  });

  if (!skipEvent) {
    await loadScript(scriptUrl.toString(), {
      id: 'epsilon-loginregistration',
      replace: true,
    });
  }
};
