export const adChoiceLogo = 'https://www.iheart.com/static/assets/aclogo.png';
export const appleTouchIcon =
  'https://www.iheart.com/static/assets/apple-touch-icon.png';
export const billingHero =
  'https://www.iheart.com/static/assets/billing_hero.jpg';
export const blankLogoTile =
  'https://www.iheart.com/static/assets/blank-logo-tile.png';
export const blankMP4 = 'https://www.iheart.com/static/assets/blank.mp4';
export const devices = 'https://www.iheart.com/static/assets/devices.png';
export const empty = 'https://www.iheart.com/static/assets/empty.png';
export const emptyPlaylist =
  'https://www.iheart.com/static/assets/Playlist-Empty.png';
export const favicon = 'https://www.iheart.com/static/assets/favicon.ico';
export const fbLogo = 'https://www.iheart.com/static/assets/fb_logo.png';
export const fiveHundred = 'https://www.iheart.com/static/assets/500.png';
export const fourOhFour = 'https://www.iheart.com/static/assets/404.png';
export const genreGame =
  'https://www.iheart.com/static/assets/genre-game-bg.jpg';
export const hero = 'https://www.iheart.com/static/assets/hero.png';
export const iheartLogoTile =
  'https://www.iheart.com/static/assets/iheart-logo-tile.png';
export const listenInBackground =
  'https://www.iheart.com/static/assets/listen-in-bkrnd.png';
export const placeholder =
  'https://www.iheart.com/static/assets/placeholder.png';
export const redBgV2 = 'https://www.iheart.com/static/assets/red-bg-v2.jpg';
