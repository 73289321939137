import logger, { CONTEXTS } from 'modules/Logger';
import reduxFactory from 'state/factory';
import { getBlockedPIIBehaviors, getEmail } from 'state/Profile/selectors';
import { getPageParameters, getScriptParameters } from './helpers';
import { getProfileId } from 'state/Session/selectors';
import { loadScript } from '@iheartradio/web.utilities';
import type { EventTypeMap } from '../types';

export const epsilonPageView = async (data: EventTypeMap['page_view']) => {
  const store = reduxFactory();

  const skipEvent = getBlockedPIIBehaviors(store.getState()).sanitizeStreams;
  const profileId = getProfileId(store.getState());
  const email = getEmail(store.getState());

  const pageParameters = getPageParameters(data);
  const scriptParameters = await getScriptParameters(
    pageParameters,
    profileId,
    'pageView',
    email,
  );

  const url = `${window.location.protocol}//${window.location.host}/tag_path/profile/visit/js/1_0`;

  const scriptUrl = new URL(url);

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, val] of Object.entries(scriptParameters)) {
    scriptUrl.searchParams.append(key, String(val));
  }

  logger.info([CONTEXTS.ANALYTICS, 'Epsilon PageView'], {
    data,
    pageParameters,
    scriptParameters,
    skipEvent,
    url: scriptUrl.toString(),
  });

  if (!skipEvent) {
    await loadScript(scriptUrl.toString(), {
      id: 'epsilon-pageview',
      replace: true,
    });
  }
};
