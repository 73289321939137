export const SET_PODCAST_EPISODES = 'Episode/SET_PODCAST_EPISODES';
export const SET_PODCAST_HOSTS = 'Podcast/SET_PODCAST_HOSTS';
export const SET_PODCAST_PROFILE = 'Podcast/SET_PODCAST_PROFILE';
export const SET_IS_FOLLOWED = 'Podcast/SET_IS_FOLLOWED';
export const RECEIVED_FOLLOWED = 'Podcast/RECEIVED_FOLLOWED';
export const RECEIVED_PODCAST_CATEGORIES =
  'Podcast/RECEIVED_PODCAST_CATEGORIES';
export const RECEIVED_PODCAST_CATEGORY = 'Podcast/RECEIVED_PODCAST_CATEGORY';
export const REORDER_PODCAST_EPISODES = 'Episode/REORDER_PODCAST_EPISODES';
export const SET_PODCAST_EPISODE_PLAY_PROGRESS =
  'Podcast/SET_PODCAST_EPISODE_PLAY_PROGRESS';
export const SET_PODCAST_CATEGORY_IDS = 'Podcast/SET_PODCAST_CATEGORY_IDS';
export const RECEIVED_PODCASTS = 'Podcast/RECEIVED_PODCASTS';
export const FEATURED_PODCAST_CATEGORY = 'Featured';
export const PODCAST_DIRECTORY_COLLECTION = 'collections/podcast-directory';
export const PODCAST_FEATURED_COLLECTION = 'collections/featured-podcasts';
export const PODCAST_POPULAR_COLLECTION = 'collections/popular-podcasts';
export const PODCAST_ARTICLES_LOADED = 'Podcast/PODCAST_ARTICLES_LOADED';

// These id's are used in PowerAMP API: api/v3/podcast/categories/{id}
export const PODCAST_FEATURED_CATEGORY_ID = '76';
export const PODCAST_POPULAR_CATEGORY_ID = '82';
export const RECEIVED_NETWORKS = 'Podcast/RECEIVED_NETWORKS';
