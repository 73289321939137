import logger, { CONTEXTS } from 'modules/Logger';
import reduxFactory from 'state/factory';
import { getBlockedPIIBehaviors, getEmail } from 'state/Profile/selectors';
import { getPageParameters, getScriptParameters } from './helpers';
import { getProfileId } from 'state/Session/selectors';
import { loadScript } from '@iheartradio/web.utilities';
import type { EventTypeMap } from '../types';

let lastPlayedId = '';

export const epsilonStreamStart = async (
  data: EventTypeMap['stream_start'],
) => {
  const { pageName } = window?.analyticsData?.events?.active ?? {};

  const store = reduxFactory();

  const id = data.station.get('id');
  const isNew = id !== lastPlayedId;
  const skipEvent =
    getBlockedPIIBehaviors(store.getState()).sanitizeStreams || !isNew;
  lastPlayedId = id;

  const profileId = getProfileId(store.getState());
  const email = getEmail(store.getState());

  const pageParameters = getPageParameters({
    pageName,
  } as EventTypeMap['page_view']);
  const scriptParameters = await getScriptParameters(
    pageParameters,
    profileId,
    'streamStart',
    email,
  );

  const url = `${window.location.protocol}//${window.location.host}/tag_path/profile/visit/js/1_0`;

  const scriptUrl = new URL(url);

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, val] of Object.entries(scriptParameters)) {
    scriptUrl.searchParams.append(key, String(val));
  }

  logger.info([CONTEXTS.ANALYTICS, 'Epsilon StreamStart'], {
    ...data,
    pageName,
    scriptParameters,
    skipEvent,
    isNew,
    url: scriptUrl.toString(),
  });

  if (!skipEvent) {
    await loadScript(scriptUrl.toString(), {
      id: 'epsilon-streamstart',
      replace: true,
    });
  }
};
