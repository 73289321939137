import buildScriptQueue from 'ads/utils/buildScriptQueue';
import logger, { CONTEXTS } from 'modules/Logger';
import { ControllerNames } from 'ads/slotControllers/types';

const HEADER_BIDDING_TIMEOUT = 2000;

type AmazonFetchBidsResponse = {
  amznactt: string;
  amznbid: string;
  amzniid: string;
  amznp: string;
  amznsz: string;
  size: string;
  slotID: string;
};

const { load, enqueue } = buildScriptQueue({
  globalVar: 'apstag',
  queueKey: null,
  scopedName: 'aps',
});

export const loadAmazon = (
  scriptUrl: string,
  { apsPubId }: { apsPubId: string },
) =>
  load(scriptUrl, ({ aps }) => {
    aps.init({
      pubID: apsPubId,
      adServer: 'googletag',
    });
  });

export const fetchAmazonBids = async (
  slotId: string,
  dimensions: Array<[number, number]>,
) => {
  const apsSlot = {
    slotID: slotId,
    slotName: slotId,
    sizes: dimensions,
  };
  await enqueue(
    ({ aps }) =>
      new Promise<void>(resolve => {
        logger.info(
          [
            CONTEXTS.ADS,
            ControllerNames.Google,
            'fetchAmazonBids',
            `request - ${slotId}`,
          ],
          ['fetching Amazon bids', { apsSlot }],
        );
        aps.fetchBids(
          {
            slots: [apsSlot],
            timeout: HEADER_BIDDING_TIMEOUT,
          },
          (responses: Array<AmazonFetchBidsResponse>) => {
            logger.info(
              [
                CONTEXTS.ADS,
                ControllerNames.Google,
                'fetchAmazonBids',
                `response - ${slotId}`,
              ],
              ['Amazon bids fetched', { apsSlot, responses }],
            );
            resolve();
          },
        );
      }),
  );
};
