import styled from '@emotion/styled';

const Img = styled('img')({
  display: 'block',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  transition: 'opacity 500ms ease, visibility 500ms ease',
  width: '100%',
});

export default Img;
