import { RequestState } from './types';

export const ADD_TRACKS_TO_PLAYLIST = 'ADD_TRACKS_TO_PLAYLIST';
export const DELETE_PLAYLIST = 'iHR/Playlist/DELETE_PLAYLIST';
export const DELETE_TRACKS = 'iHR/Playlist/DELETE_TRACKS';
export const FOLLOW_MY_MUSIC_PLAYLIST_ID = 'FOLLOW_MY_MUSIC_PLAYLIST_ID';
export const FOLLOW_PLAYLIST_ID = 'iHR/Playlist/FOLLOW_PLAYLIST_ID';
export const REMOVE_PLAYLIST_FROM_HISTORY =
  'iHR/Playlist/REMOVE_PLAYLIST_FROM_HISTORY';
export const RECEIVED_PLAYLISTS = 'iHR/Playlist/RECEIVED_PLAYLISTS';
export const SHUFFLE_PLAYLIST = 'iHR/Playlist/SHUFFLE_PLAYLIST';
export const TOGGLE_FOLLOW = 'iHR/Playlist/TOGGLE_FOLLOW';
export const TRIGGER_FROM_HERO = 'iHR/Playlist/TRIGGER_FROM_HERO';
export const TRIGGER_FROM_PLAYER_ROW = 'iHR/Playlist/TRIGGER_FROM_PLAYER_ROW';
export const TRIGGER_FROM_TILE = 'iHR/Playlist/TRIGGER_FROM_TILE';
export const UPDATE_PLAYLIST_NAME = 'UPDATE_PLAYLIST_NAME';
export const UPDATE_TRACK_ORDER = 'iHR/Playlist/UPDATE_TRACK_ORDER';
export const playlistStationType = 'COLLECTION';
export const UNFOLLOW_PLAYLIST_ID = 'iHR/Playlist/UNFOLLOW_PLAYLIST_ID';
export const UNFOLLOW_MY_MUSIC_PLAYLIST_ID = 'UNFOLLOW_MY_MUSIC_PLAYLIST_ID';
export const SET_PLAYLIST_REQUEST_STATE =
  'iHR/Playlist/SET_PLAYLIST_REQUEST_STATE';

export const REQUEST_STATE: Record<string, RequestState> = {
  FAILED: 'REQUEST_STATE/FAILED',
  FETCHED: 'REQUEST_STATE/FETCHED',
  NEEDS_AUTH: 'REQUEST_STATE/NEEDS_AUTH',
  NOT_REQUESTED: 'REQUEST_STATE/NOT_REQUESTED',
  PARTIAL: 'REQUEST_STATE/PARTIAL',
  PENDING: 'REQUEST_STATE/PENDING',
};
