export const REQUEST_ARTIST = 'iHR/Artist/REQUEST';
export const RECEIVE_ARTISTS = 'iHR/Artist/RECEIVE';
export const RECEIVE_PROFILE = 'iHR/Artist/RECEIVE_PROFILE';
export const ARTIST_ERROR = 'iHR/Artist/ERROR';
export const SET_IS_FAVORITE = 'iHR/Artist/SET_IS_FAVORITE';

export const REQUEST_SIMILARS = 'iHR/Artist/REQUEST_SIMILARS';
export const RECEIVE_SIMILARS = 'iHR/Artist/RECEIVE_SIMILARS';
export const SIMILARS_ERROR = 'iHR/Artist/SIMILARS_ERROR';

export const customStationTypes = ['ARTIST', 'CUSTOM'];
export const RECEIVE_AD_GENRE = 'iHR/Artist/RECEIVE_AD_GENRE';
