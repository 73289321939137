/**
 * From underscore.string, escape reg ex char in a string
 * @param str String to be processed
 * @return String after regex is escaped
 */
export function _escapeRegExp(str: string): string {
  if (!str) return '';
  return String(str).replaceAll(/([!$()*+./:=?[\\\]^{|}])/g, '\\$1');
}

export function defaultToWhiteSpace(
  characters: null | RegExp | string,
): string {
  if (characters === null) return '\\s';
  if (characters instanceof RegExp) return characters.source;
  return `[${_escapeRegExp(characters)}]`;
}
