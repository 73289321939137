export default {
  DEFAULT: 0,
  DIR_CUSTOM_FILTERED: 140,
  DIR_CUSTOM_MAIN_FEATURED_ARTISTS: 139,
  DIR_GENRE_MAIN: 141,
  DIR_GENRE_RAIL_RECS: 142,
  DIR_LIVE_FILTERED: 138,
  DIR_PLAYLIST_DECADE: 146,
  DIR_PLAYLIST_GENRE: 147,
  DIR_PLAYLIST_MAIN_FEATURED: 143,
  DIR_PLAYLIST_PERFECTFOR: 145,
  DIR_PLAYLIST_RAIL_RECS: 144,
  DIR_PODCAST_FILTERED_RAIL_RECS: 136,
  DIR_PODCAST_RAIL_RECS: 135,
  HOME_RECENT: 3,
  HOME_RECS: 4,
  PLAYER_DIALOG_ARE_YOU_STILL_LISTENING: 35,
  PLAYER_PLAY: 6,
  PLAYER_REPLAY: 10,
  PLAYER_RESUME_AFTER_REPLAY: 34,
  PLAYER_REWIND: 9,
  PLAYER_SKIP: 8,
  PROF_ALBUM_AUTOPLAY: 76,
  PROF_ALBUM_PLAY: 77,
  PROF_ALBUM_RAIL_RECS: 79,
  PROF_ARTIST_ALBUMS: 69,
  PROF_ARTIST_AUTOPLAY: 65,
  PROF_ARTIST_MAIN_ALBUMS: 74,
  PROF_ARTIST_MAIN_LATEST_RELEASE: 73,
  PROF_ARTIST_MAIN_POPULAR_ON: 75,
  PROF_ARTIST_MAIN_SONGS: 66,
  PROF_ARTIST_PLAY: 68,
  PROF_ARTIST_RAIL_RECS: 71,
  PROF_ARTIST_SIMILAR: 70,
  PROF_ARTIST_SONGS: 67,
  PROF_EPISODE_AUTOPLAY: 90,
  PROF_EPISODE_PLAY: 91,
  PROF_EPISODE_RAIL_RECS: 92,
  PROF_FAVORITES_AUTOPLAY: 97,
  PROF_FAVORITES_MAIN_ARTISTS: 99,
  PROF_FAVORITES_PLAY: 98,
  PROF_FAVORITES_RAIL_RECS: 100,
  PROF_GENRE_MAIN_FEATURED_STATIONS: 57,
  PROF_GENRE_MAIN_TOP_ARTISTS: 56,
  PROF_GENRE_MAIN_TOP_STATIONS: 55,
  PROF_GENRE_PLAY: 54,
  PROF_GENRE_RAIL_RECS: 58,
  PROF_LIVE_AUTOPLAY: 59,
  PROF_LIVE_MAIN_SIMILAR: 63,
  PROF_LIVE_PLAY: 60,
  PROF_LIVE_RAIL_RECENT: 64,
  PROF_LIVE_RAIL_RECS: 62,
  PROF_LIVE_RECENT: 196,
  PROF_LIVE_SIMILAR: 61,
  PROF_MY_ALBUMS_ALBUM_ALBUM: 104,
  PROF_MY_ALBUMS_ALBUM_SONGS: 105,
  PROF_MY_ALBUMS_ALBUMS: 103,
  PROF_MY_ARTISTS_ARTIST_ARTIST: 109,
  PROF_MY_ARTISTS_ARTIST_SONGS: 108,
  PROF_MY_ARTISTS_ARTISTS: 106,
  PROF_MY_MUSIC_PLAYLISTS: 101,
  PROF_MY_MUSIC_SONGS: 107,
  PROF_MY_SONGS_SONGS: 102,
  PROF_NEW4U_AUTOPLAY: 167,
  PROF_NEW4U_PLAY: 169,
  PROF_NEW4U_RADIO_AUTOPLAY: 171,
  PROF_NEW4U_RADIO_PLAY: 172,
  PROF_NEWS_MAIN_E2E: 115,
  PROF_NEWS_RAIL_RECS: 116,
  PROF_PLAYLIST_AUTOPLAY: 93,
  PROF_PLAYLIST_MYPLAYLIST_HERO: 270,
  PROF_PLAYLIST_MYPLAYLIST_USERTRACKS: 271,
  PROF_PLAYLIST_PLAY_HERO: 270,
  PROF_PLAYLIST_PLAY: 94,
  PROF_PLAYLIST_RADIO_AUTOPLAY: 164,
  PROF_PLAYLIST_RADIO_PLAY: 165,
  PROF_PLAYLIST_RADIO_SONGS: 166,
  PROF_PLAYLIST_SONGS: 96,
  PROF_PODCAST_RAIL_RECS: 88,
  PROF_SONG_AUTOPLAY: 81,
  PROF_SONG_PLAY: 82,
  PROF_SONG_RAIL_RECS: 83,
  PROF_SONG_SIMILAR: 84,
  PROF_USER_FAVORITE_SONGS: 124,
  PROF_USER_FAVORITE_STATIONS_FAVORITES: 126,
  PROF_USER_FAVORITE_STATIONS_RECENT: 127,
  PROF_USER_RAIL_RECS: 125,
  PROF_USER_HISTORY: 117,
  PROF_USER_HISTORY_TRACK: 118,
  PROF_USER_MAIN_FAVORITE_SONGS: 123,
  PROF_USER_MAIN_HISTORY: 119,
  PROF_USER_MAIN_HISTORY_TRACK: 120,
  RESP_WIDGET_AUTOPLAY: 159,
  RESP_WIDGET_EPISODE_PLAY: 163,
  RESP_WIDGET_LIVE_MAIN_RECENTLY_PLAYED: 160,
  RESP_WIDGET_PLAYER_JS: 249,
  RESP_WIDGET_PLAYER_PLAY: 157,
  RESP_WIDGET_PLAYLIST_TRACK_PLAY: 168,
  RESP_WIDGET_PROFILE_EPISODE_PLAY: 162,
  SEARCH_RESULTS_FILTERED: 37,
  SEARCH_RESULTS_MAIN: 37,
  SEARCH_RESULTS_MAIN_NOW_PLAYING: 44,
  SEARCH_RESULTS_MAIN_TOP_HIT: 36,
  WIDGET_AUTOPLAY: 159,
  YOUR_LIBRARY_ALBUM_SONGS: 105,
  YOUR_LIBRARY_ARTIST_SONGS: 108,
  YOUR_LIBRARY_SONGS: 107,
} as const;
