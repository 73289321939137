export const RECEIVE_LOCATION_CONFIG = 'ihr/Config/RECEIVE_LOCATION_CONFIG';

export const COUNTRIES = {
  AU: 'AU',
  CA: 'CA',
  MX: 'MX',
  NZ: 'NZ',
  US: 'US',
  WW: 'WW',
} as const;
