import Events from 'modules/Analytics/constants/events';
import { epsilonLoginRegistration } from './loginRegistration';
import { epsilonPageView } from './pageView';
import { epsilonStreamStart } from './streamStart';
import type { EventTypeMap } from '../types';
import type { TrackerConfig } from '@iheartradio/web.signal';

const epsilonTracker = (enabled: boolean): TrackerConfig<EventTypeMap> => ({
  enabled,
  name: 'Epsilon Tracker',

  events: {
    [Events.PageView]: epsilonPageView,
    [Events.StreamStart]: epsilonStreamStart,
    [Events.PostLogin]: epsilonLoginRegistration,
    [Events.PostRegistration]: epsilonLoginRegistration,
  },
});

export default epsilonTracker;
