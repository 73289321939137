export const REJECT_PROFILE = 'iHR/Profile/REJECT_PROFILE';
export const REQUEST_PROFILE = 'iHR/Profile/REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'iHR/Profile/RECEIVE_PROFILE';
export const SAVE_PREFERENCE = 'iHR/Profile/SAVE_PREFERENCE';
export const SAVE_PROPERTY = 'iHR/Profile/SAVE_PROPERTY';
export const SAVE_IS_UNDERAGE = 'iHR/Profile/SAVE_IS_UNDERAGE';
export const REQUEST_RECURLY_HISTORY =
  'iHR/Profile/REQUEST_RECURLY_BILLING_HISTORY';
export const TOGGLE_PII_BLOCKING = 'iHR/entitlements/TOGGLE_PII_BLOCKING';
export const LINK_IOS_TO_ALEXA = 'iHR/entitlements/LINK_IOS_TO_ALEXA';
export const SET_CANADA_PRIVACY_STATUS = 'setCanadaPrivacyStatus';
