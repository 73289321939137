export const ENTITLEMENTS_LOADED = 'iHR/entitlements/LOADED';

export const MORE_SKIPS = 'MORE_SKIPS';

export const PLAY_PLAYLIST = 'PLAY_PLAYLIST';

export const PLAY_PLAYLIST_RADIO = 'PLAY_PLAYLIST_RADIO';

export const SHOW_PLAYLIST_RADIO = 'SHOW_PLAYLIST_RADIO';

export const PLAY_ALBUM = 'ALBUM_PLAY_ARTISTPF';

export const SHOW_ALBUM_UPSELL = 'SHOW_UPSELL_ALBUM_PLAY_ARTISTPF_WEB';

export const ARTIST_2_START = 'ARTIST2START_AMP';

export const SONG_2_START = 'SONG2START_AMP';

export const AD_FREE = 'ADFREE_BANNER';

export const ADFREE_CUSTOM = 'ADFREE_CUSTOM';

export const REPLAY = 'REPLAY';

export const EDIT_PLAYLIST = 'EDIT_PLAYLIST';

export const MANAGE_USER_PLAYLIST = 'MANAGE_USER_PLAYLIST';

export const EDIT_PLAYABLE_AS_RADIO = 'EDIT_PLAYABLE_AS_RADIO';

export const ADD_TRACK_TO_PLAYLIST = 'ADD_TRACK_OVERFLOW_PLAYLIST';

export const SAVE_TRACK_PLAYLIST = 'SAVE_TRACK_OVERFLOW_PLAYLIST';

export const SHUFFLE = 'SHUFFLE_PLAYLIST';

export const SHUFFLE_CURATED = 'SHUFFLE_CURATED';

export const PREROLL_FREE = 'ADFREE_PREROLL';

export const SHOW_MYMUSIC_LIBRARY = 'SHOW_MYMUSIC_LIBRARY';

export const SHOW_UPGRADE_BUTTON = 'SHOW_UPGRADE_NAV_WEB';

export const SHOW_PLAYLIST_UPSELL = 'SHOW_UPSELL_PLAYLIST_WEB';

export const UNLIMITED_MYMUSIC_PLAYBACK = 'MYMUSIC_PLAYBACK_UNLIMITED';

export const SHOW_ALBUM_OVERFLOW = 'SHOW_ALBUM_OVERFLOW_ARTISTPF';

export const ADD_ALBUM_TO_PLAYLIST =
  'SHOW_ADD_ALBUM_OVERFLOW_PLAYLIST_ARTISTPF';

export const SAVE_ALBUM_OVERFLOW = 'SHOW_SAVE_ALBUM_OVERFLOW_ARTISTPF';

export const SHOW_TRACK_OVERFLOW = 'SHOW_TRACK_OVERFLOW_ARTISTPF';

export const SHOW_ADD_TRACK_TO_PLAYLIST =
  'SHOW_ADD_TRACK_OVERFLOW_PLAYLIST_ARTISTPF';

export const SHOW_SAVE_TRACK = 'SHOW_SAVE_TRACK_OVERFLOW_ARTISTPF';

export const SHOW_ALBUM_HEADER = 'SHOW_ALBUM_HEADER_PLAY_ARTISTPF';

export const SHOW_SAVE_TRACK_PLAYER = 'SHOW_SAVE_TRACK_OVERFLOW_PLAYER';

export const SAVE_TRACK_PLAYER = 'SAVE_TRACK_OVERFLOW_PLAYER';

export const SHOW_REPLAY = 'SHOW_REPLAY_PLAYER';

export const SCRUB_COLLECTION = 'SCRUB_COLLECTION';

export const SCRUB_CUSTOM = 'SCRUB_CUSTOM';

export const SCRUB_MYMUSIC = 'SCRUB_MYMUSIC';

export const MYMUSIC_LIBRARY = 'MYMUSIC_LIBRARY';

export const CUSTOM_AD_UPSELL = 'SHOW_UPSELL_ADFREE_CUSTOM';

export const MORE_SKIPS_UPSELL = 'SHOW_UPSELL_MORE_SKIPS_WEB';

export const ALLACCESS_PREVIEW = 'ALLACCESS_PREVIEW';

export const SHOW_UPSELL_SONG2START = 'SHOW_UPSELL_SONG2START';

export const SHOW_SAVE_ALBUM_HEADER_OVERFLOW =
  'SHOW_SAVE_ALBUM_HEADER_OVERFLOW_ALBUMPF';
