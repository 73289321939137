enum Events {
  AAPreview = 'aa_preview',
  Back15 = 'back_15',
  Click = 'click',
  CreateContent = 'create_content',
  Error = 'error',
  FailedSubscription = 'failed_subscription',
  FollowArtist = 'follow_artist',
  FollowPodcast = 'follow_podcast',
  FollowPlaylist = 'follow_playlist',
  FollowUnfollow = 'follow_unfollow',
  Forward30 = 'forward_30',
  GenreUpdate = 'genre_update',
  InAppMessageExit = 'iam_exit',
  InAppMessageOpen = 'iam_open',
  ItemSelected = 'item_selected',
  ListenTime = 'listen_time',
  Logout = 'logout',
  LogoutForced = 'logout_forced',
  Mute = 'mute',
  OpenClosePlayer = 'full_player_open_close',
  PageView = 'page_view',
  Password = 'password_action',
  Pause = 'pause',
  PaymentFrameExit = 'payment_frame_exit',
  PaymentFrameOpen = 'payment_frame_open',
  Play = 'play',
  PlaybackError = 'playback_error',
  PostLogin = 'post_login',
  PostRegistration = 'post_registration',
  RegGateExit = 'reg_gate_exit',
  RegGateOpen = 'reg_gate_open',
  Replay = 'replay',
  Rewind = 'rewind',
  SaveDelete = 'save_delete',
  SaveStation = 'save_station',
  Search = 'search',
  Share = 'share',
  Shuffle = 'shuffle',
  Skip = 'skip',
  SpeedChange = 'speed_change',
  Stop = 'stop',
  StreamEnd = 'stream_end',
  StreamFallback = 'stream_fallback',
  StreamStart = 'stream_start',
  Subscribe = 'subscribe',
  Thumbing = 'thumbing',
  ThumbsUp = 'thumbs_up',
  ThumbsDown = 'thumbs_down',
  UnfollowArtist = 'unfollow_artist',
  UnfollowPodcast = 'unfollow_podcast',
  UnfollowPlaylist = 'unfollow_playlist',
  Unmute = 'unmute',
  UnsaveStation = 'unsave_station',
  UnthumbUp = 'unthumb_up',
  UnthumbDown = 'unthumb_down',
  UpsellExit = 'upsell_exit',
  UpsellOpen = 'upsell_open',
  ViewContent = 'view_content',
}

export default Events;
