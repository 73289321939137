export const TRIGGERED = 'Triggered';
export const BANNER = 'Banner';
export const NEW_SCREEN = 'new_screen';
export const PUSH = 'Push';
export const DEEPLINK = 'Deeplink';
export const EMAIL = 'Email';
export const GENERAL = 'GENERAL';
export const PLUS = 'PLUS';
export const ALL_ACCESS = 'ALL ACCESS';
export const ALL_ACCESS_ANNUAL = 'ALL ACCESS ANNUAL';
export const ALL_ACCESS_FAMILY = 'ALL ACCESS FAMILY';
export const VENDOR = 'vendor';
export const NATIVE = 'Native';
export const MODAL = 'modal';
export const BACK = 'back';
export const DISMISS = 'dismiss';
export const UPGRADE_SUCCESS = 'upgrade_success';
export const UPGRADE_FAILURE = 'upgrade_failure';
export const ARTIST = 'artist';
export const IHEART_US_PREMIUM_TRIAL = 'IHEART_US_PREMIUM_TRIAL';

export const PLAYER_CUSTOM_MAX_SKIP_LIMIT = 1;
export const PLAYER_CUSTOM_REPLAY_TRACK_CUSTOM = 2;
export const PLAYER_CUSTOM_ADD_TRACK_TO_PLAYLIST = 3;
export const PLAYER_CUSTOM_REPLAY_TRACK_LIVE = 4;
export const MY_MUSIC_UPGRADE_BANNER = 6;
export const MY_MUSIC_SONGS_TILES = 7;
export const MY_MUSIC_ALBUMS_TILES = 8;
export const MY_MUSIC_ARTISTS_TILES = 9;
export const MY_MUSIC_CREATE_NEW_PLAYLIST = 10;
export const ONBOARDING_DEEPLINK = 11;
export const CUSTOM_PLAYLIST_UPGRADE_BANNER = 12;
export const ONBOARDING_PUSH = 20;
export const ALBUM_PROFILE_ALBUM_HEADER_PLAY = 21;
export const ARTIST_PROFILE_SAVE_ALBUM_TO_MY_MUSIC = 22;
export const ARTIST_PROFILE_ADD_ALBUM_TO_PLAYLIST = 23;
export const ALBUM_PROFILE_ARTIST_SAVE_TRACK_TO_MY_MUSIC = 24;
export const ALBUM_PROFILE_ARTIST_TRACK_ADD_TO_PLAYLIST = 25;
export const ALBUM_PROFILE_SAVE_ALBUM_TO_MY_MUSIC = 26;
export const ALBUM_PROFILE_ADD_ALBUM_TO_PLAYLIST = 27;
export const ARTIST_PROFILE_TOP_TRACKS_SAVE_TRACK_TO_MY_MUSIC = 28;
export const ARTIST_PROFILE_TOP_TRACKS_ADD_TO_PLAYLIST = 29;
export const ARTIST_PROFILE_ALBUMS_LIST_SAVE_ALBUM_TO_MY_MUSIC = 30;
export const ARTIST_PROFILE_ALBUMS_LIST_ADD_TO_PLAYLIST = 31;
export const SIDE_NAV_UPGRADE_BANNER = 54;
export const LINK_FROM_HELP_SITE = 33;
export const SETTINGS_SUBSCRIPTION_LEARN_MORE = 34;
export const FOR_YOU_UPGRADE_BANNER = 35;
export const ARTIST_PROFILE_LATEST_SAVE_ALBUM_TO_MY_MUSIC = 37;
export const MY_PLAYLIST_UPGRADE_BANNER = 38;
export const PLAYLIST_RESPONSIVE_WIDGET = 46;
export const LINK_FROM_LOCAL_SITE = 52;
export const ARTIST_PROFILE_LATEST_ADD_TO_PLAYLIST = 53;
export const HEADER_UPGRADE_BUTTON = 54;
export const SETTINGS_SUBSCRIPTION_SUBSCRIBE_AGAIN = 57;
export const LINK_FROM_CUSTOMER_CARE = 58;
export const LINK_FROM_AD = 59;
export const UPSELL_SONG_2_START = 61;
export const LINK_FROM_RESET_PASSWORD = 62;
export const SHARED_PLAYLIST_UPGRADE_BANNER = 63;
export const LINK_FROM_PLUS_RESET_PASSWORD = 65;
export const LINK_FROM_PREMIUM_RESET_PASSWORD = 66;
export const LINK_FROM_AFFILIATE_SITE_PLUS = 72;
export const LINK_FROM_AFFILIATE_SITE_ALL_ACCESS = 72;
export const LINK_FROM_AFFILIATE_SITE_BOTH = 72;
export const LINK_FROM_SAMSUNG = 5000;
export const PLAYLIST_RADIO_UPGRADE_BANNER = 109;
export const PLAYLIST_RADIO_SAVE_PLAYLIST = 110;
export const PLAYLIST_RADIO_SHUFFLE = 113;
export const NEW4U_RADIO_UPGRADE_BANNER = 114;
export const NEW4U_RADIO_SAVE_PLAYLIST = 115;
export const NEW4U_RADIO_SHUFFLE = 118;
