import { kebabCase } from './kebab-case.js';

export function _slugify(value: string): string {
  return kebabCase(
    value.replaceAll("'", '').replaceAll(/[^\s\w-]/g, ''),
  ).toLocaleLowerCase('en-US');
}

import { defaultToWhiteSpace } from './string.js';

const from = 'ąàáäâãåæćęèéëêìíïîłńòóöôõðøùúüûñçżź';
const to = 'aaaaaaaaceeeeeiiiilnooooooouuuunczz';
const regex = new RegExp(defaultToWhiteSpace(from), 'g');

export function slugify(value: string): string {
  // Do some cleanup first

  const cleanString = value
    .replace("'", '')
    .toLocaleLowerCase()
    .replace(regex, c => {
      const index = from.indexOf(c);
      return to.charAt(index) ?? '-';
    })
    .replaceAll(/[^\s\w-]/g, '') // Removes any non-word characters or white spaces.
    .normalize('NFKD')
    .replaceAll(/[\u0300-\u036F]/g, '');

  return kebabCase(cleanString);
}
