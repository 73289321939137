import Events from 'modules/Analytics/constants/events';
import init from 'vendor/snapchatPixel';
import { asyncIsPrivacyOptOut } from './privacyOptOut';
import { executeScript, TrackerConfig } from '@iheartradio/web.signal';
import { stationTypeMap } from 'state/Stations/constants';
import type { EventsHandler, EventTypeMap } from './types';

const snapchatTrack: (event: string, data: unknown) => void = (event, data) => {
  const snaptr = window?.snaptr;
  return snaptr ? snaptr('track', event, data) : null;
};

// These user agents are found when browsing in a Snapchat WebView
// Pulled from https://businesshelp.snapchat.com/en-US/a/specs-web-view
const SNAPCHAT_WEB_VIEW_USER_AGENTS = {
  iPhone:
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Snapchat/10.72.0.2 (like Safari/604.1)',
  Android:
    'Mozilla/5.0 (Linux; Android 7.0; SM-G950U Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/60.0.3112.107 Mobile Safari/537.36 Snapchat/10.17.0.0 (SM-G950U; Android 7.0#G950USQU1AQG4#24; gzip)',
};

const trackedEvents = [Events.PageView, Events.Play, Events.StreamStart];

const events: EventsHandler = async (eventName, payload) => {
  if (await asyncIsPrivacyOptOut()) {
    return;
  }

  if (trackedEvents.includes(eventName)) {
    if (eventName === Events.PageView) {
      snapchatTrack('PAGE_VIEW', payload);
    } else if (eventName === Events.Play) {
      const { type, typeId, name, id } =
        payload as EventTypeMap[typeof Events.Play];
      snapchatTrack('VIEW_CONTENT', {
        item_category: type,
        item_ids: typeId,
        transaction_id: id,
        description: name,
      });
    } else if (eventName === Events.StreamStart) {
      if (
        Object.values(SNAPCHAT_WEB_VIEW_USER_AGENTS).includes(
          navigator.userAgent,
        )
      ) {
        // IHRWEB-16147 Web and Widget use the same tacker implementation but send different payloads
        // TODO: Widget should have a separate tracker implementation.
        // web
        const { station, podcastEpisodeData } =
          payload as EventTypeMap[typeof Events.StreamStart];
        // widget
        const { id, name, title, type } = payload as unknown as {
          id: string;
          name: string;
          title: string;
          type: string;
        };
        const snapchatData: {
          episodeId?: number | string;
          episodeName?: string;
          id: string;
          name: string;
          type?: string;
        } = {
          id: station?.get('id') ?? id,
          name: station?.get('artistName') ?? station?.get('name') ?? name,
          type: station?.get('seedType') ?? type,
        };
        if (snapchatData.type === stationTypeMap.PODCAST) {
          snapchatData.episodeId = podcastEpisodeData?.id ?? id;
          snapchatData.episodeName = podcastEpisodeData?.title ?? title;
        }
        snapchatTrack('PURCHASE', snapchatData);
      }
    }
  }
};

const snapchatPixelTracker = (
  snapchatPixelId: string,
  enabled: boolean,
): TrackerConfig<EventTypeMap> => {
  return {
    enabled,

    name: 'Snapchat Pixel',

    initialize: async () => {
      if (await asyncIsPrivacyOptOut()) {
        return;
      }
      executeScript(init(snapchatPixelId));
    },

    events,
  };
};
export default snapchatPixelTracker;
