enum Type {
  Array = 'array',
  AsyncFunction = 'asyncfunction',
  Error = 'error',
  Function = 'function',
  Null = 'null',
  Number = 'number',
  Object = 'object',
  Promise = 'promise',
  Regexp = 'regexp',
  String = 'string',
  Symbol = 'symbol',
  Undefined = 'undefined',
}

/**
 * @remarks {@link typeOf typeOf()} returns the type of the given value.
 *
 * @param {unknown} value The value to check.
 * @returns array | function | null | number | object | promise | regexp | string | symbol | undefined
 *
 * @example
 *
 * ```ts
 * typeOf(''); // string
 * ```
 */
export const typeOf = (value: unknown): Type =>
  Object.prototype.toString
    .call(value)
    .slice(8, -1)
    .toLowerCase() as unknown as Type;

/**
 * @remarks {@link isArray isArray()} checks if a given value is an array.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isArray([]); // true
 * ```
 */
export const isArray = (value: unknown): value is Array<any> =>
  typeOf(value) === Type.Array;

/**
 * @remarks {@link isAsyncFunction isAsyncFunction()} checks if a given value is an async function.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isAsyncFunction(() => {}); // false
 * isAsyncFunction(async () => {}); // true
 * ```
 */
export const isAsyncFunction = (
  value: unknown,
): value is (...args: Array<any>) => Promise<any> =>
  typeOf(value) === Type.AsyncFunction;

/**
 * @remarks {@link isFunction isFunction()} checks if a given value is a function.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isFunction(() => {}); // true
 * isFunction(async () => {}); // true
 * ```
 */
export const isFunction = (
  value: unknown,
): value is (...args: Array<any>) => any =>
  typeOf(value).includes(Type.Function);

/**
 * @remarks {@link isError isError()} checks if a given value is an error.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isError(new Error(':())); // true
 * ```
 */
export const isError = (value: unknown): value is Error =>
  typeOf(value).includes(Type.Error);

/**
 * @remarks {@link isNull isNull()} checks if a given value is null.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isNull(null); // true
 * ```
 */
export const isNull = (value: unknown): value is null =>
  typeOf(value) === Type.Null;

/**
 * @remarks {@link isNumber isNumber()} checks if a given value is a number.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isNumber(1); // true
 * ```
 */
export const isNumber = (value: unknown): value is number =>
  typeOf(value) === Type.Number;

/**
 * @remarks {@link isObject isObject()} checks if a given value is an object.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isObject({}); // true
 * ```
 */
export const isObject = (value: unknown): value is Record<any, any> =>
  typeOf(value) === Type.Object;

/**
 * @remarks {@link isPromise isPromise()} checks if a given value is a promise.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isPromise(Promise.resolve()); // true
 * ```
 */
export const isPromise = (value: unknown): value is Promise<any> =>
  typeOf(value) === Type.Promise;

/**
 * @remarks {@link isRegexp isRegexp()} checks if a given value is a regular expression.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isRegexp(/\/); // true
 * ```
 */
export const isRegexp = (value: unknown): value is RegExp =>
  typeOf(value) === Type.Regexp;

/**
 * @remarks {@link isString isString()} checks if a given value is a string.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isString(''); // true
 * ```
 */
export const isString = (value: unknown): value is string =>
  typeOf(value) === Type.String;

/**
 * @remarks {@link isSymbol isSymbol()} checks if a given value is a symbol.
 *
 * @param {unknown} value The value to check.
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isSymbol(new Symbol()); // true
 * ```
 */
export const isSymbol = (value: unknown): value is symbol =>
  typeOf(value) === Type.Symbol;

/**
 * @remarks {@link isUndefined isUndefined()} checks if a given value is undefined.
 *
 * @param {unknown} value The value to check.
 *
 * @returns boolean
 *
 * @example
 *
 * ```ts
 * isUndefined(undefined); // true
 * ```
 */
export const isUndefined = (value: unknown): value is undefined =>
  typeOf(value) === Type.Undefined;

type Nil = null | undefined;

/**
 * @remarks {@link isNil isNil()} checks if a given value is undefined or null.
 *
 * @param value - The value to check.
 *
 * @returns Whether or not the value is nil.
 *
 * @example
 *
 * ```ts
 * isNil(undefined); // true
 * isNil(null); // true
 * isNil('foo'); // false
 * isNil(123); // false
 * ```
 */
export const isNil = (value: unknown): value is Nil => value == undefined;

/**
 * @remarks {@link isNotNil isNotNil()} checks if a given value is undefined or null.
 *
 * @param value - The value to check.
 *
 * @returns Whether or not the value is nil.
 *
 * @example
 *
 * ```ts
 * isNotNil(undefined); // false
 * isNotNil(null); // false
 * isNotNil('foo'); // true
 * isNotNil(123); // true
 * ```
 */
export const isNotNil = <T = unknown>(value: T): value is NonNullable<T> =>
  value != null;
