export enum GrowlVariants {
  FavoriteChanged = 'FavoriteChanged',
  FollowedChanged = 'FollowedChanged',
  Notify = 'Notify',
  OutOfSkips = 'OutOfSkips',
  PlayerError = 'PlayerError',
  PlaylistFollowed = 'PlaylistFollowed',
  SocialNetworkError = 'SocialNetworkError',
  ThumbsUpdated = 'ThumbsUpdated',
}

export enum GrowlIcons {
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  Close = 'Close',
  Info = 'Info',
  Deleted = 'Deleted',
  HeartFilled = 'HeartFilled',
}
