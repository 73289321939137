import { STATION_TYPE } from 'constants/stationTypes';

export const CONTEXTS = Object.freeze({
  AB_TESTS: 'AB_TESTS',
  ADS: 'ADS',
  ALBUM: 'ALBUM',
  ANALYTICS: 'ANALYTICS',
  BRAZE: 'BRAZE',
  ARTIST: 'ARTIST',
  AUTH: 'AUTH',
  CHROMECAST: 'CHROMECAST',
  CLIENT: 'CLIENT',
  COOKIES: 'COOKIES',
  CREATE_RADIO: 'CREATE_RADIO',
  CRITICAL: 'CRITICAL',
  CUSTOM: 'CUSTOM',
  ERROR_HANDLING_MIDDLEWARE: 'ERROR_HANDLING_MIDDLEWARE',
  EVENTS: 'EVENTS',
  FAMILY_PLAN: 'FAMILY_PLAN',
  FEATURED: 'FEATURED',
  FORCED_LOGOUT: 'FORCED_LOGOUT',
  FORM: 'FORM',
  GENRE: 'GENRE',
  GRACENOTE: 'GRACENOTE',
  HEADER_BIDDING: 'HEADER_BIDDING',
  HUB: 'HUB',
  JW_PLAYER: 'JW_PLAYER',
  LIVE: 'LIVE',
  LOCAL_STORAGE: 'LOCAL_STORAGE',
  LOCATION: 'LOCATION',
  LOGOUT: 'LOGOUT',
  MFR: 'MFR',
  MY_MUSIC: 'MY_MUSIC',
  NEWS: 'NEWS',
  OD: 'OD',
  OPTIMISTICIDIOTLOOKUP: 'OPTIMISTICIDIOTLOOKUP',
  PLAYBACK: 'PLAYBACK',
  PLAYBACK_ADS: 'PLAYBACK_ADS',
  PLAYER: 'PLAYER',
  PLAYLIST: 'PLAYLIST',
  PODCAST: 'PODCAST',
  REACT: 'REACT',
  RECS: 'RECS',
  RECURLY_ANALYTICS: 'RECURLY_ANALYTICS',
  REDUX: 'REDUX',
  REPORTING: 'REPORTING',
  ROUTER: 'ROUTER',
  SEARCH: 'SEARCH',
  SERVER: 'SERVER',
  SERVICES: 'SERVICES',
  SETTINGS: 'SETTINGS',
  SOCIAL: 'SOCIAL',
  SUBSCRIPTION: 'SUBSCRIPTION',
  TESTS: 'TESTS',
  TRANSPORT: 'TRANSPORT',
  TRITON: 'TRITON',
  UI: 'UI',
  UNCAUGHT_EXCEPTION: 'UNCAUGHT_EXCEPTION',
  UNHANDLED_REJECTION: 'UNHANDLED_REJECTION',
  UTILS: 'UTILS',
  VIDEO_WIDGET: 'VIDEO_WIDGET',
  WIDGET: 'WIDGET',
  YOUR_LIBRARY: 'YOUR_LIBRARY',
});

export const STATION_TYPE_CONTEXT_MAP = {
  [STATION_TYPE.ALBUM]: CONTEXTS.ALBUM,
  [STATION_TYPE.ARTIST]: CONTEXTS.ARTIST,
  [STATION_TYPE.COLLECTION]: CONTEXTS.PLAYLIST,
  [STATION_TYPE.FAVORITES]: CONTEXTS.MFR,
  [STATION_TYPE.LIVE]: CONTEXTS.LIVE,
  [STATION_TYPE.MY_MUSIC]: CONTEXTS.MY_MUSIC,
  [STATION_TYPE.PLAYLIST_RADIO]: CONTEXTS.PLAYLIST,
  [STATION_TYPE.PODCAST]: CONTEXTS.PODCAST,
  [STATION_TYPE.SONG]: CONTEXTS.ARTIST,
  [STATION_TYPE.TALK]: CONTEXTS.PODCAST,
  [STATION_TYPE.TALK_EPISODE]: CONTEXTS.PODCAST,
  [STATION_TYPE.TRACK]: CONTEXTS.ARTIST,
  [STATION_TYPE.CUSTOM]: CONTEXTS.CUSTOM,
  [STATION_TYPE.FEATURED]: CONTEXTS.FEATURED,
};
