import buildScriptQueue from 'ads/utils/buildScriptQueue';

const { load, enqueue } = buildScriptQueue({
  globalVar: 'moatPrebidApi',
  queueKey: null,
  scopedName: 'moatPB',
});

export const loadMoat = load;

export const setMoatTargeting = () => {
  enqueue(({ moatPB }) => {
    // TODO: Is there a method to set it on just one slot?
    moatPB?.setMoatTargetingForAllSlots();
  });
};
