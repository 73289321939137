enum LocalStorageKeys {
  LoggerEnabled = 'loggerEnabled',
  LoggerFilter = 'loggerFilter',
  PlayerState = 'ihr-player-state',
  SocialTimeout = 'socialTimeout',
  UnderAge = 'ihr-underage',
  DismissedMyPlaylistBanner = 'dismissedMyPlaylistWelcomeBanner',
}

export default LocalStorageKeys;
