export const SET_PREMIUM_BACKGROUND = 'Hero/SET_PREMIUM_BACKGROUND';
export const SET_BACKGROUND = 'Hero/SET_BACKGROUND';
export const SET_HAS_HERO = 'Hero/SET_HAS_HERO';
export const SET_HIDE_HERO = 'Hero/SET_HIDE_HERO';
export const SET_HOME_HERO = 'Hero/SET_HOME_HERO';
export const SET_TITLE = 'Hero/SET_TITLE';
export const RESET = 'Hero/RESET';

export const heroTypes = {
  GENERIC: 'GENERIC',
  PREMIUM: 'PREMIUM',
} as const;
