import styled from '@emotion/styled';

const BackgroundCloseButton = styled('button')({
  backgroundColor: 'transparent',
  border: 'none',
  height: '100%',
  left: 0,
  margin: 0,
  padding: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 0,
});

export default BackgroundCloseButton;
