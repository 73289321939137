import { loadScript } from '@iheartradio/web.utilities';
import type { EventTypeMap } from './types';
import type { TrackerConfig } from '@iheartradio/web.signal';

type GoogleFirebaseConfig = {
  account: string;
};

const googleFireBaseTracker = ({
  account,
}: GoogleFirebaseConfig): TrackerConfig<EventTypeMap> => ({
  enabled: !!account,

  name: 'Google Firebase',

  initialize: async () => {
    function gtag(...args: any) {
      window.dataLayer.push(args);
    }

    const script = `https://www.googletagmanager.com/gtag/js?id=${account}`;
    await loadScript(script);
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', account);
  },

  events: () => {},
});

export default googleFireBaseTracker;
