export const OD_BACK_WAIT = 2; // how long we need to wait before restarting rather than moving backwards in OD playback

export const PLAYER_STATE = {
  BUFFERING: 'BUFFERING',
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  PAUSED: 'PAUSED',
  PLAYING: 'PLAYING',
} as const;

export const PLAYER = {
  CHROMECAST: 'CHROMECAST',
  FAVORITE_STATIONS_FAVORITE: 'FAVORITE_STATIONS_FAVORITE',
  FAVORITE_STATIONS_RECENT: 'FAVORITE_STATIONS_RECENT',
  LISTEN_HISTORY_STATION: 'LISTEN_HISTORY_STATION',
  LISTEN_HISTORY_TRACK: 'LISTEN_HISTORY_TRACK',
  PLAY: 'PLAY',
  REPLAY: 'REPLAY',
  RETURN_AUTOPLAY: 'RETURN_AUTOPLAY',
  REWIND: 'REWIND',
  SKIP: 'SKIP',
  SKIP_LIMIT_RECOMMEND: 'SKIP_LIMIT_RECOMMEND',
} as const;
