export default {
  artist: {
    album: '/artist/:slugifiedId/albums/:sectionId',
    albums: '/artist/:slugifiedId/albums',
    directory: '/artist',
    genre: '/artist/genre/:slugOrId',
    news: '/artist/:slugifiedId/news',
    profile: '/artist/:slugifiedId',
    similar: '/artist/:slugifiedId/similar',
    song: '/artist/:slugifiedId/songs/:sectionId',
    songs: '/artist/:slugifiedId/songs',
  },
  genre: {
    directory: '/genre',
    game: '/genre-options',
    profile: '/genre/:slugifiedId',
  },
  live: {
    city: '/live/country/:countryCode/city/:sectionId',
    country: '/live/country/:countryCode',
    directory: '/live',
    profile: '/live/:slugifiedId',
  },
  misc: {
    favorites: '/favorites/:slugifiedId?',
    forYou: '/for-you',
    notFound: '/404',
    refresh: [
      '/alter-ego/',
      '/festival/',
      '/fiesta-latina/',
      '/flash/back-to-school/',
      '/graduation-playlist/',
      '/iheartcountry-festival/',
      '/jingle-ball/',
      '/music-awards/',
      '/music-festival/',
      '/podcast-awards/',
      '/resolutions-playlist/',
      '/valentines-playlists/',
      '/wango-tango/',
    ],
    search: '/search',
    serverError: '/500',
    testerOptions: '/testerOptions',
    userInfo: '/userInfo',
    alexaLinkIOS: '/alexa/applink',
    metaWearablesLink: '/meta/wearables',
  },
  news: {
    contentArticle: '/content/:slug',
    directory: '/news',
    legacy: '/news/:slug',
    topicDirectory: '/topic/:slug',
  },
  playlist: {
    chill: '/weeklychill',
    directory: '/playlist',
    mixtape: '/mixtape/',
    my: '/playlist/my/',
    profile: '/playlist/:slugOrId',
    subDirectory: '/playlist/collections/:collection/:category/:subcategory',
    workout: '/weeklyworkout',
  },
  podcast: {
    category: '/podcast/category/:slugifiedId',
    directory: '/podcast',
    episode: '/podcast/:slugifiedId/episode/:episodeSlug',
    episodes: '/podcast/:slugifiedId/:section(episodes)',
    networks: '/podcast/networks',
    news: '/podcast/:slugifiedId/news',
    profile: '/podcast/:slugifiedId',
    show: '/show/:slugOrId?/:section(episodes)?',
    widget: '/widget',
  },
  profile: {
    billingHistory: '/billing-history',
    byId: '/profile/:slugOrId',
    familyValidation: '/family-validation',
    history: '/my/history',
    promo: '/offers',
    settings: '/my/settings',
    songs: '/my/songs',
  },
  recurly: {
    confirmation: '/subscribe/confirmation',
    history: '/billing-history',
    subscribe: '/subscribe',
    updatePayment: '/update-payment',
    upgrade: '/upgrade',
  },
  yourLibrary: {
    albumProfile: '/your-library/:section(albums)/:slugifiedId',
    albums: '/your-library/:section(albums)',
    artistProfile: '/your-library/:section(artists)/:slugifiedId',
    artists: '/your-library/:section(artists)',
    index: '/your-library/',
    playlists: '/your-library/:section(playlists)',
    podcasts: '/your-library/:section(podcasts)',
    recentlyPlayed: '/your-library/:section(recently-played)',
    savedStations: '/your-library/:section(saved-stations)/',
    songs: '/your-library/:section(songs)',
  },
};
