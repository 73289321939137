import { hashEmail } from 'utils/hashEmail';
import type { EventTypeMap } from '../types';
import type { PageParams, PromoParams, ScriptParams } from './types';

export const getPromoId = ({
  isHome,
  isDirectory,
  isProfile,
  isLibrary,
}: PromoParams) => {
  if (isHome) {
    return 1;
  } else if (isDirectory || isLibrary) {
    return 2;
  } else if (isProfile) {
    return 3;
  } else {
    return 4;
  }
};

export const getPageParameters = (
  data: EventTypeMap['page_view'],
): PageParams => {
  const isProfile = data.pageName?.includes('profile');
  const profileType = isProfile ? data.pageName?.replace('_profile', '') : null;

  const isDirectory = data.pageName?.includes('directory');
  const directoryType =
    isDirectory ? data.pageName?.replace('_directory', '') : null;

  const isHome = data.pageName === 'home';
  const isLibrary = data.pageName === 'your_library';

  const name =
    (data.name ?? data.pageType)?.toLowerCase().replaceAll(' ', '-') ?? '';

  const promoId = getPromoId({ isHome, isDirectory, isProfile, isLibrary });

  return {
    pageName: data.pageName as string,
    isProfile,
    isDirectory: isDirectory || isLibrary,
    isHome,
    promoId,
    profileType,
    directoryType,
    name,
  };
};

export const getScriptParameters = async (
  pageParams: PageParams,
  profileId: number | null,
  event: 'pageView' | 'streamStart' | 'registrationLogin',
  email?: string | null,
): Promise<ScriptParams> => {
  return {
    dtm_fid: event === 'pageView' ? 5610 : 5611,
    dtm_cid: 82943,
    dtm_cmagic: 'f2930d',
    dtm_promo_id:
      // eslint-disable-next-line no-nested-ternary
      event === 'pageView' ? pageParams.promoId
      : event === 'streamStart' ? 101
      : 941,
    ...(profileId !== null && profileId !== undefined ?
      { dtm_user_id: profileId }
    : {}),
    ...(email !== null && email !== undefined ?
      { dtm_email_hash: await hashEmail(email) }
    : {}),
    ...(pageParams.isDirectory && event === 'pageView' ?
      {
        dtmc_department: pageParams.directoryType ?? pageParams.name,
      }
    : {}),
    ...(pageParams.isProfile && event === 'pageView' ?
      {
        dtmc_category: pageParams.name,
      }
    : {}),
    ...((
      pageParams.isProfile &&
      pageParams.profileType !== null &&
      pageParams.profileType !== undefined &&
      event === 'pageView'
    ) ?
      {
        dtmc_department: pageParams.profileType,
      }
    : {}),
    dtm_loc: encodeURIComponent(window.location.href),
    cachebuster: Date.now(),
  };
};
